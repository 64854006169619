/* Home styles */
.home__content img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.home_section {
  position: relative;
}

.home_card {
  position: absolute;
  top: 0;
  left: 10%;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.3);
  padding-left: 1rem;
  width: 350px;
  height: 100%;
  border-radius: 0.5rem;
}

.home_card span {
  color: var(--black-color);
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
}

/* Fin Home Styles */

/*************** BREAKPOINTS ************/
@media screen and (max-width: 996px) {
  .home_card {
    position: absolute;
    top: 0;
    left: 10%;
    z-index: 10;
    background-color: rgba(255, 255, 255, 0.3);
    padding-left: 1rem;
    width: 250px;
    height: 100%;
    border-radius: 0.5rem;
  }

  .home_card span {
    color: var(--black-color);
    font-size: calc(var(--h3-font-size) - 0.7rem);
  }
}

/***** For Small Devices *****/
@media screen and (max-width: 554px) {
  .home_card {
    position: absolute;
    top: 0;
    left: 10%;
    z-index: 10;
    background-color: rgba(255, 255, 255, 0.3);
    padding: 1rem;
    width: 190px;
    height: 100%;
    border-radius: 0.5rem;
  }

  .home_card span {
    color: var(--black-color);
    font-size: calc(var(--h3-font-size) - 1.1rem);
  }
}