/* Header Block */
/* Main header with static position */
.main_header_area {
    background-color: var(--body-color);
    display: block;
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;  
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  /* Main header without static position
  .main_header_area {
    background-color: var(--body-color);
    display: block;
    width: 100%;;
  }
  /* End header without static position */
  
  /* Header top area */
  .header_top_area {
    background-color: #1e2126;
    display: grid;
    grid-template-columns: 1.5fr 0.5fr;
    height: auto;
  }
  
  /* Pull left (Company Info Contact) */
  .header_top_area .list__info{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0 1.2rem;
    margin: 0;
  }
  
  .header_top_area .pull-left .list__info .list__item {
    border-left: 1px solid rgba(255, 193, 0, 0.4);
    text-align: center;
    line-height: 40px;
    top: 0;
    bottom: 0;
  }
  
  .header_top_area .pull-left .list__info .list__item a{
    color: var(--toggle-color);
  }
  
  .header_top_area .pull-left .list__info .list__item .contact__info__icon{
    font-size: 20px;
    padding-right: 20px;
    color: rgba(255, 255, 255, 0.8);
  }
  /* Fin Pull Left */
  
  /* Pull Right (Social Networks) */
  .header_top_area .pull-right .list__social {
    display: flex;
    justify-content: right;
    margin: 0;
    padding-right: 1rem;
  }
  
  .header_top_area .pull-right .list__social .social__item{
    text-align: center;
    line-height: 40px;
  }
  
  .header_top_area .pull-right .list__social .social__item a{
    font-size: 20px;
    padding-left: 10px;
    color: rgba(255, 255, 255, 0.8);
  }
  /* Fin header top area */

  .main_menu_area {
    display: flex;
    justify-content: space-between;
    background-color: var(--body-color);
    align-items: center;
  }
  
  .main_menu_area .nav__logo .logo {
    width: 100%;
    height: auto;
    max-width: 150px;
    margin-left: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  
  .nav__menu .nav__list {
    display: flex;
  }
  
  .nav__menu .nav__list .nav__item {
    padding: 1.5rem  1.5rem;
    font-size: var(--normal-font-size);
    font-weight: var(--font-bold);
    text-transform: uppercase;
  }

  .nav__link {
    color: var(--title-color-dark);
    font-weight: var(--font-bold);
  }

  .active-link {
    color: var(--toggle-color);
  }

  .nav__menu .nav__list .nav__item a:hover {
    color: var(--toggle-color);
    transition: .9s;
  }

  
 
  .nav__close,
  .nav__toggle {
    color: var(--toggle-color);
    font-size: 2.5rem;
    margin-right: 20px;
    display: none;
  }
  
  /* Fin header top area */

  
/* For large devices */
@media screen and (max-width: 900px) {
  .nav__logo {
    padding: 0.5rem 0 0.5rem;
  }

  .main_menu_area .nav__logo .logo {
    width: 100%;
    height: auto;
    max-width: 150px;
    margin-left: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .nav__menu {
    position: fixed;
    top: -200%;
    left: 0;
    width: 100%;
    background-color: var(--body-color);
    transition: .3s;
    padding: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 5px 10px 8px rgba(0, 0, 0, 0.1);
    z-index: var(--z-modal);
  }

    /* Show menu */
  .show-menu {
      top: 0;
  }

  .nav__menu .nav__list {
    display: inline;
    width: 100%;
    
  }

  .nav__menu .nav__list .nav__item {
    margin-right: 5rem;
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
    padding: 1.5rem 0 1.5rem 1.5rem;
  }

  .nav__menu .nav__list .nav__item:hover {
    background-color: var(--toggle-color);
    transition: 2.5s;
  }
  
  .nav__close,
  .nav__toggle {
    display: block;
  }

  .nav__close {
    position: absolute;
    top: 1rem;
    right: 0.6rem;
    cursor: pointer;
  }




}

/* For medium devices */
@media screen and (max-width: 740px) { 
  .header_top_area {
    display: block;
  } 
  .pull-right {
    display: none;
  }
  .pull-left .list__info {
    display: flex;
  }
  .list__item {
    padding: 0 0.2rem;
  }
}

/* For small devices */
@media screen and (max-width: 554px){

}

/* For medium devices 
@media screen and (max-width: 780px) {
    img.logo {
        max-width: 90px;
    }

    .nav__menu {
        position: fixed;
        top: calc(var(--header-height) + 3.25rem);
        left: 0;
        width: 100%;
        background-color: var(--container-color);
    }

    .nav__list {
        display: block;
        margin-left: 4.5rem;
        margin-right: 4.5rem;
        border-top: 1px solid rgba(0, 0, 0, 0.25);
    }

    .nav__item {
        padding: 1rem 0;
        font-size: var(--small-font-size);
    }

    .nav__close {
        display: block;
    } 
}*/