/*========== GOOGLE FONTS ==================*/
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

/*========== VARIABLES CSS ================*/
:root {
  /*========== Colos ==============*/
  /* Color mode HSL (Hue, Saturation, Lightness) */
  --hue: 0;
  --sat: 0%;
  --title-color: hsl(var(--hue), var(--sat), 20%);
  --title-color-white: #f3f3f3;
  --title-color-dark: hsl(var(--hue), var(--sat), 0%);
  --text-color: hsl(var(--hue), var(--sat), 45%);
  --body-color: hsl(var(--hue), var(--sat), 100%);
  --container-color: #ffffff;
  --black-color: #1e2126;
  --toggle-color: #ffc100;

  /*========= Font & Typography =========*/
  --body-font: "Roboto", sans-serif;


  /* 0.5rem = 8px || 1rem = 16px */
  --big-font-size: 3.5rem;
  --title-font-size: 2.5rem;
  --h1-font-size: 3rem;
  --h2-font-size: 2.75rem;
  --h3-font-size: 2.25rem;
  --h4-font-size: 2rem;
  --h5-font-size: 1.75rem;
  --h6-font-size: 1.25rem;
  --normal-font-size: 1rem;
  --small-font-size: 0.875rem;
  --smaller-font-size: 0.813rem;
  --tiny-font-size: 0.625rem;

  /*========= Font Weight ========*/
  --font-normal: 400;
  --font-medium: 500;
  --font-bold: 700;

  /*======== Margin Bottom =======*/
  --mb-0-25: 0.25rem;
  --mb-0-5: 0.5rem;
  --mb-0-75: 0.75rem;
  --mb-1: 1rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;
  --mb-2-5: 2.5rem;
  --mb-3: 3rem;

  /*======== z index ==========*/
  --z-tooltipl: 10;
  --z-fixd: 100;
  --z-modal: 1000;

  /* Responsive typography */
  @media screen and (max-width: 992px) {
    :root {
      --big-font-size: 2.75rem;
      --h1-font-size: 1.75rem;
      --h2-font-size: 1.60rem;
      --h3-font-size: 1.45rem;
      --h4-font-size: 1.3rem;
      --h5-font-size: 1.15rem;
      --h6-font-size: 1rem;
      --normal-font-size: 0.983rem;
      --small-font-size: 0.813rem;
      --smaller-font-size: 0.75rem;
    }
  }
}

/*============= BASE ==============*/

html {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  display: block;
}

* {
  box-sizing: border-box;
}

body,
button,
input,
textarea {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
}

body {
  margin: 0;
  background-color: var(--body-color);
  color: var(--text-color);
}

h1 {
  color: var(--toggle-color);
  font-size: var(--big-font-size);
  font-weight: var(--font-bold);
}


h2 {
  color: var(--black-color);
  font-size: var(--title-font-size);
  font-weight: var(--font-medium);
  text-transform: uppercase;
}

h3,
h4,
h5,
h6 {
  color: var(--title-color);
  font-weight: var(--font-bold);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
  border: none;
  outline: none;
}

/*
img {
  width: 100%;
  height: auto;
  object-fit: cover;
}*/

/* Header Block
.main_header_area {
  background-color: red;
  display: block;
  width: 100%;
}

/* Header top area
.header_top_area {
  background-color: #1e2126;
  display: grid;
  grid-template-columns: 1.5fr 0.5fr;
}

/* Pull left (Company Info Contact)
.header_top_area .list__info{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 1.2rem;
}

.header_top_area .pull-left .list__info .list__item {
  border-left: 1px solid rgba(255, 193, 0, 0.4);
  text-align: center;
  line-height: 40px;
  top: 0;
  bottom: 0;
}

.header_top_area .pull-left .list__info .list__item a{
  color: var(--text-color)
}

.header_top_area .pull-left .list__info .list__item .contact__info__icon{
  font-size: 20px;
  padding-right: 20px;
  color: rgba(255, 255, 255, 0.8);
}
/* Fin Pull Left

/* Pull Right (Social Networks)
.header_top_area .pull-right .list__social {
  display: flex;
  justify-content: right;
  margin-right: 20px;
}

.header_top_area .pull-right .list__social .social__item{
  text-align: center;
  line-height: 40px;
}

.header_top_area .pull-right .list__social .social__item a{
  font-size: 20px;
  padding-left: 10px;
  color: rgba(255, 255, 255, 0.8);
}
/* Fin header top area
.main_menu_area {
  display: flex;
  justify-content: space-between;
  background-color: var(--body-color);
  align-items: center;
}


.main_menu_area .nav__logo .logo {
  width: 100%;
  height: auto;
  max-width: 150px;
  margin-left: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.nav__menu .nav__list {
  display: flex;
}

.nav__menu .nav__list .nav__item {
  padding: 1.5rem  1.5rem;
  font-size: var(--normal-font-size);
  font-weight: var(--font-bold);
  text-transform: uppercase;
}

.nav__toggle {
  color: var(--toggle-color);
  font-size: 2.5rem;
  margin-right: 20px;
  display: none;
}

/* Fin header top area */


/*============ BREAKPOINTS ==========*/
/* For large devices */
@media screen and (max-width:996px) {
  h1 {
    font-size: calc(var(--big-font-size) - 1rem);
  }

  h2 {
    color: var(--black-color);
    font-size: calc(var(--title-font-size) - 0.5rem);
    font-weight: var(--font-medium);
    text-transform: uppercase;
  }

}

/* For medium devices */
@media screen and (max-width: 830px) {}

/* For small devices */
@media screen and (max-width: 554px) {
  h1 {
    font-size: calc(var(--big-font-size) - 1.7em);
  }
}

@media screen and (max-width: 340px) {}