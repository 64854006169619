/* Work Section Styles */
.work_section {
  background-color: var(--body-color);
  display: block;
  padding-bottom: 2rem;
  border-bottom: 4px solid green;
}

.work_section .section__title {
  margin: 0;
  text-align: right;
  padding: 3rem 3rem 0 0;
}

.about_section-subtitle {
  display: block;
  font-size: var(--small-font-size);
  font-weight: --var(--font-bold);
  color: var(--toggle-color);
  text-align: right;
  padding: 0 3rem 3rem 0;

}

.work__section .work__container {
  width: 100%;
  height: 5px;
}

.work__content {
  text-align: center;
}

.work__content img {
  width: 80%;
}


.work__content__data {
  position: absolute;
  width: 100%;
  top: 0;
  background: rgba(255, 255, 255, 0.4);
}

.work__content__data h3 {
  padding-left: 2rem;
  color: var(--toggle-color);
  font-size: 1.8rem;
}

.work__content__data p {
  padding-left: 2rem;
  color: var(--toggle-color);
  font-size: 1.1rem;
}

.before {
  background-color: red;
}

/* For small devices */
@media screen and (max-width: 554px) {
  .work__content__data {
    position: absolute;
    width: 100%;
    top: 0;
    background: rgba(255, 255, 255, 0.4);
  }

  .work__content__data h3 {
    padding-left: 2rem;
    color: var(--black-color);
    font-weight: var(--font-bold);
    font-size: 1rem;
  }

  .work__content__data p {
    padding-left: 2rem;
    color: var(--black-color);
    font-size: 0.9rem;
  }
}


/***** For Small Devices *****/
@media screen and (max-width: 640px) {
  .work__content img {
    width: 80%;
  }
}