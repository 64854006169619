/* About Styles Section */
.about_section {
    display: block;
    background-color: var(--body-color);
    padding-bottom: 2rem;
    border-bottom: 3px solid green;
  }
  
  .about_section .section__title {
    text-align: left;
    margin: 0;
    padding: 3rem 0 0 3rem;
  }
  
  .section__subtitle {
    font-size: var(--small-font-size);
    padding-left: 3rem;
    font-weight: --var(--font-bold);
    color: var(--toggle-color);
  }
  
  .about__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  
  .about__information__left {
    padding: 1rem 3rem;
    text-align: justify;
    color: var(--title-color);
  }
  
  .about__service {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    margin: 3rem 3rem 0;
  }
  
  .about__service__box {
    display: c;
    background-color: rgba(255, 193, 0, 0.9);
    border-radius: 0 0 0.5rem 0.5rem;
  }

  .about__service__box img {
    width: 100%;
    height: 250px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }
  
  .about__service__box h3 {
    text-align: center;
    margin:0;
    padding: 1.2rem 1rem;
    color: var(--black-color);
    font-weight: var(--font-bold);
  }

  .about__service__subtitle {
    display: block;
    color: var(--black-color);
    text-align: justify;
    padding: 0 1rem 1rem;
  }

  .about__information__right img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  /* End About Styles Section */

  /*************** BREAKPOINTS ************/
  /* Medium devices */
  @media screen and (max-width: 996px) {
    .about__container {
      display: block;
    }

    .about__container .about__information__right {
      padding: 0 2.5rem;
    }
  }

  /***** For Small Devices *****/
  @media screen and (max-width: 554px) {
    
  }