.contact_section {
    background-color: var(--container-color);
    display: block;
    padding-bottom: 2rem;
}

.contact_section-title {
    margin: 0;
    text-align: center;
    padding: 3rem 0 0 0;
}

.contact_section-subtitle {
    display: block;
    text-align: center;
    font-size: var(--small-font-size);
    font-weight: --var(--font-bold);
    color: var(--toggle-color);
}

.contact_container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    margin-left: 3rem;
    margin-right: 3rem;
}

.contact__content {
    display: block;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
}

.contact__title {
    font-size: var(--h6-font-size);
    text-align: center;
    font-weight: var(--font-medium);
    padding-bottom: 1.5rem;
}

.contact__info {
    display: block;
}

.contact__card {
    background-color: var(--container-color);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.7rem;
    padding: 1rem;
    text-align: center;
    margin-bottom: 1rem;
}

.contact__card-icon {
    font-size: 2.5rem;
    color: var(--toggle-color);
}

.contact__card-data {
    display: block;
    color: var(--black-color);
    padding: 0.5rem 0 0 0;
}

.contact__card-button {
    display: block;
    margin-top: 0.8rem;
    cursor: pointer;
    color: var(--text-color);
}

.contact__form {
    width: 100%;
}

.contact__form-div {
    height: 4rem;
    margin-bottom: 2rem;
    position: relative;
}

.contact__form-area {
    height: 11rem;
}

.contact__form-tag {
    background-color: var(--body-color);
    font-size: var(--smaller-font-size);
    left: 1.25rem;
    padding: 0.25rem;
    position: absolute;
    top: -0.75rem;
    z-index: 10;
    color: var(--black-color);
}

.contact__form-input {
    background: none;
    border: 2px solid rgba(0, 0, 0, 0.3);
    border-radius: 0.75rem;
    color: var(--text-color);
    height: 100%;
    left: 0;
    outline: none;
    padding: 1.5rem;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
}

.button {
    padding: 1.25rem 2rem;
    background-color: var(--toggle-color);
    color: var(--container-color);
    border-radius: 1rem;
    font-weight: var(--font-medium);
}

.button:hover {
    background-color: rgba(255, 193, 0, 0.5);
    cursor: pointer;
}

/* Work modal CSS Styles */
.contact__modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: var(--z-modal);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    opacity: 0;
    visibility: hidden;
    transition: .6s;
}

.contact__modal-content {
    width: 500px;
    position: relative;
    background-color: var(--container-color);
    padding: 2.5rem 2.5rem 2.5rem;
    border-radius: 1.5rem;
}

.contact__modal-title {
    display: block;
    text-align: center;
}

.contact__modal-icon {
    display: block;
    color:rgba(255, 193, 0);
    font-size: 2.5rem;
    text-align: center;
}

.contact__modal-message {
    color: var(--black-color);
    display: block;
    text-align: center;
}

.contact__modal-button {
    display: block;
    width: 150px;
    height: 45px;
    margin: auto;
    padding-top: 1.5rem;
    background-color: rgba(255, 193, 0);
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 1.2rem;
    cursor: pointer;
    justify-content: center;
}

.contact__modal-button:hover {
    background-color: rgba(255, 193, 0, 0.5);
}

.contact__modal-button span {
    display: block;
    text-align: center;
    margin-top: -0.8rem;
    color: var(--container-color);
}

/* Active Modal */
.active-modal {
    opacity: 1;
    visibility: visible;
}

